import React from "react";
import { Content } from "../components/content";
import { ContentLinks } from "../components/content-links";
import { H1, H2, StraplineText } from "../components/text";
import { Hero } from "../components/hero";
import { Layout } from "../components/layout";
import { Seo } from "../components/seo";
import HeroImage from "../images/pages/homepage/hero.jpg";
import SEOImage from "../images/wvs-india-seo.jpg";

const Index = () => {
  return (
    <Layout>
      <Seo lang="en" title="WVS India" image={SEOImage} />
      <Hero image={HeroImage} fullscreen>
        <H1>
          Teaching best practices in veterinary field surgery and anesthesia for
          the welfare of animals
        </H1>
      </Hero>
      <Content variant="tertiary">
        <H2>Who we are</H2>
        <StraplineText>
          The International Centre is a facility run by the Worldwide
          Veterinary Service, to provide the latest skills, support and supplies
          to vets and animal welfare workers in India.
        </StraplineText>
        <StraplineText>
          Supporting animal welfare charities by providing veterinary skills
          programmes, our aim is to improve the health and welfare of animals, with a
          particular attention to eradication of rabies and humane dog
          population management
        </StraplineText>
      </Content>
      <ContentLinks />
    </Layout>
  );
};

export default Index;
